import React, { useContext, useEffect, useState, useCallback, useMemo, lazy, Suspense } from 'react';
import AllianceCalendar from 'alliance-calendar';
import logoMerlin from '../../Assets/img/logo-merlin-without-text.png';
import '../../Styles/planning.css';
import Header2Planning from "../../Components/Headers/Header2Planning";
import { toast } from "react-toastify";
import moment from "moment";
import AuthContext from "../../Context/AuthContext";
import Loading from "../../Components/Loading";
import planningActiviteAPI from "../../Services/planningActiviteAPI";
import groupeActiviteAPI from "../../Services/groupeActiviteAPI";
import prestataireAPI from "../../Services/prestataireAPI";
import ActiviteAPI from "../../Services/activiteAPI";
import clientAPI from "../../Services/clientAPI";

const ModalActivite = lazy(() => import("../../Components/Modal/ModalActivite"));
const ModalActiviteEdit = lazy(() => import("../../Components/Modal/ModalActiviteEdit"));

const Planning = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [groupeActivite, setGroupeActivite] = useState([]);
    const [optionsGroupeActivite, setOptionsGroupeActivite] = useState([]);
    const [prestataire, setPrestataire] = useState({});
    const [optionsPrestataire, setOptionsPrestataire] = useState([]);
    const [client, setClient] = useState({});
    const [clients, setClients] = useState([]);
    const [optionsClient, setOptionsClient] = useState([]);
    const [activite, setActivite] = useState({});
    const [activites, setActivites] = useState([]);
    const [activitesWithoutParent, setActivitesWithoutParent] = useState([]);
    const [optionsActivite, setOptionsActivite] = useState([]);
    const [optionsActivitesWithoutParent, setOptionsActivitesWithoutParent] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventsFiltered, setEventsFiltered] = useState(null);
    const [eventSelected, setEventSelected] = useState({});
    const { day, setDay } = useContext(AuthContext);
    const [date, setDate] = useState(moment());
    const [showEmployees, setShowEmployees] = useState(false);
    const { monthIndex } = useContext(AllianceCalendar.GlobalContext);

    const fetchGroupeActivite = useCallback(async () => {
        try {
            const response = await groupeActiviteAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.error(e);
        }
    }, []);

    const fetchPrestataire = useCallback(async () => {
        try {
            const response = await prestataireAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.error(e);
        }
    }, []);

    const fetchActivite = useCallback(async (ga = groupeActivite.value) => {
        if (ga) {
            try {
                const response = await ActiviteAPI.fetchAllByGroupeActivite(ga.id);
                setActivites(response.data);
                return response.data;
            } catch (e) {
                toast.error("Une erreur est survenue");
                console.error(e);
            }
        }
        return [];
    }, [groupeActivite]);

    const fetchActiviteWithoutParent = useCallback(async (client, ga) => {
        if (client && ga) {
            try {
                const response = await ActiviteAPI.fetchAllByClientAndParentIsNull(client.id, ga.id);
                setActivitesWithoutParent(response.data);
                setOptionsSelectActiviteWithoutParent(response.data);
                return response.data;
            } catch (e) {
                toast.error("Une erreur est survenue");
                console.error(e);
            }
        }
        return [];
    }, []);

    const fetchClients = useCallback(async () => {
        try {
            const response = await clientAPI.fetchAll();
            setClients(response.data);
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.error(e);
        }
    }, []);

    const fetchPlanningActivite = useCallback(async () => {
        try {
            // const response = await planningActiviteAPI.fetchAll();
            const response = await planningActiviteAPI.fetchAllByDate(moment(localStorage.getItem("currentDate")).format("YYYY-MM-DD HH:mm:ss"));
            const data = response.data.map(act => {
                const employes = act.employes?.map(emp => `${emp.nom} ${emp.prenom}`) || [];
                const titre = act.activites?.map(acti => acti.libelle).join(", ") || "";
                const groupeActivite = act.activites[0]?.groupeActivite.id;
                const prestataire = act.prestataire?.id;
                const client= act.client?.id;
                if (showEmployees){
                    return {
                        activite: { value: act, visible: false },
                        dateDebut: { value: act.dateDebut.toString().substring(0, 10), visible: false },
                        dateFin: { value: act.dateFin.toString().substring(0, 10), visible: false },
                        titre: { value: titre, visible: true },
                        groupeActiviteId : { value: groupeActivite, visible: false },
                        employes: { value: "Employées : " +employes.toString(), visible: true },
                        color: { value: act.activites[0]?.codeCouleur, visible: false },
                        prestataire: { value: `Prestataire : ${act.prestataire?.nom || ""}`, visible: true },
                        prestataireId : { value: prestataire, visible: false },
                        client: { value: `Client : ${act.client.nom}`, visible: true },
                        clientId : { value: client, visible: false },
                        textColor: { value: act.activites[0]?.couleurText || "#FFFFFF", visible: false },
                        isReccurente: { value: act.recurrence, visible: false },
                        details: {
                            "": `${moment.unix(act.dateDebut.toString().substring(0, 10)).format("HH:mm")} - ${moment.unix(act.dateFin.toString().substring(0, 10)).format("HH:mm")}`,
                            titre,
                            prestataire: `Prestataire : ${act.prestataire?.nom || ""}`,
                            client: `Client : ${act.client.nom}`,
                            employes
                        },
                        etat: { value: act.etat, visible: false }
                    };
                }else{
                    return {
                        activite: { value: act, visible: false },
                        dateDebut: { value: act.dateDebut.toString().substring(0, 10), visible: false },
                        dateFin: { value: act.dateFin.toString().substring(0, 10), visible: false },
                        titre: { value: titre, visible: true },
                        groupeActiviteId : { value: groupeActivite, visible: false },
                        color: { value: act.activites[0]?.codeCouleur, visible: false },
                        prestataire: { value: `Prestataire : ${act.prestataire?.nom || ""}`, visible: true },
                        prestataireId : { value: prestataire, visible: false },
                        client: { value: `Client : ${act.client.nom}`, visible: true },
                        clientId : { value: client, visible: false },
                        textColor: { value: act.activites[0]?.couleurText || "#FFFFFF", visible: false },
                        isReccurente: { value: act.recurrence, visible: false },
                        details: {
                            "": `${moment.unix(act.dateDebut.toString().substring(0, 10)).format("HH:mm")} - ${moment.unix(act.dateFin.toString().substring(0, 10)).format("HH:mm")}`,
                            titre,
                            prestataire: `Prestataire : ${act.prestataire?.nom || ""}`,
                            client: `Client : ${act.client.nom}`,
                            employes
                        },
                        etat: { value: act.etat, visible: false }
                    };
                }

            });
            setEvents(data);
            setIsLoading(false);
        } catch (e) {
            console.error(e);
        }
    }, [showEmployees]);

    const setOptionsSelectGroupeActivite = useCallback((ga) => {
        const options = ga.map(groupeA => ({ value: groupeA, label: groupeA.libelle }));
        setOptionsGroupeActivite(options.sort((a, b) => a.label.localeCompare(b.label)));
    }, []);

    const setOptionsSelectPrestataire = useCallback((prest) => {
        const options = prest.map(pr => ({ value: pr, label: pr.nom }));
        setOptionsPrestataire(options);
    }, []);

    const setOptionsSelectActivite = useCallback((a) => {
        const options = a.filter(ac => ac.enfants.length <= 0).map(ac => ({ value: ac, label: ac.libelle }));
        setOptionsActivite(options);
    }, []);

    const setOptionsSelectActiviteWithoutParent = useCallback((a) => {
        const options = a.map(ac => ({ value: ac, label: ac.libelle }));
        setOptionsActivitesWithoutParent(options.sort((a, b) => a.label.localeCompare(b.label)));
    }, [activitesWithoutParent]);

    const setOptionsSelectClient = useCallback((clients) => {
        const options = clients?.map(cl => ({ value: cl, label: cl.nom })) || [];
        setOptionsClient(options);
    }, []);

    useEffect(() => {
        fetchPlanningActivite();
        fetchClients().then(setOptionsSelectClient);
        fetchGroupeActivite().then(setOptionsSelectGroupeActivite);
        fetchPrestataire().then(setOptionsSelectPrestataire);
    }, [fetchPlanningActivite, fetchClients, fetchGroupeActivite, fetchPrestataire, setOptionsSelectClient, setOptionsSelectGroupeActivite, setOptionsSelectPrestataire]);

    useEffect(() => {
        fetchActivite().then(setOptionsSelectActivite);
    }, [fetchActivite, groupeActivite, setOptionsSelectActivite]);

    useEffect(() => {
        setActivite({});
    }, [groupeActivite]);

    useEffect(() => {
        if (events.length > 0) {
            let options = [];
            const haveGroupeActivite = groupeActivite && groupeActivite.length > 0;
            const haveActivite = activite && Object.keys(activite).length > 0;
            const haveClient = client && Object.keys(client).length > 0;
            const havePrestataire = prestataire && Object.keys(prestataire).length > 0 && prestataire.prestataire != null;

            if (haveGroupeActivite) {
                options = events.filter(eve =>
                    groupeActivite.some(ga => ga.value.id === eve.groupeActiviteId.value)
                );
            }
            if (haveActivite) {
                options = options.length ? options.filter(eve => eve.titre.value.includes(activite.label)) : events.filter(eve => eve.titre.value.includes(activite.label));
            }
            if (haveClient) {
                options = options.length ? options.filter(eve => eve.clientId.value === client.client.id) : events.filter(eve => eve.clientId.value === client.client.id);
            }
            if (havePrestataire) {
                options = options.length ? options.filter(eve => eve.prestataireId.value === prestataire.prestataire.id) : events.filter(eve => eve.prestataireId.value === prestataire.prestataire.id);
            }
            if (!haveGroupeActivite && !haveActivite && !haveClient && !havePrestataire) {
                options = events;
            }
            setEventsFiltered(options);
        }
    }, [activite, groupeActivite, client, prestataire, events, fetchPlanningActivite]);

    useEffect(() => {
        fetchPlanningActivite();
    }, [monthIndex]);


    return (
        <>
            <Header2Planning className={"mt-3"} isOpen={isOpen} setIsOpen={setIsOpen} activite={activite}
                             setActivite={setActivite} optionsActivite={optionsActivite} groupeActivite={groupeActivite}
                             setGroupeActivite={setGroupeActivite} optionsGroupeActivite={optionsGroupeActivite}
                             client={client} optionsClient={optionsClient} setClient={setClient}
                             prestataire={prestataire}
                             setPrestataire={setPrestataire} optionsPrestataire={optionsPrestataire} showEmployees={showEmployees} setShowEmployees={setShowEmployees}/>
            {isLoading ? <Loading /> : (
                <AllianceCalendar.AllianceCalendar setOpenModal={setOpenInfo}
                                  setOpenEditModal={setIsOpenEdit} setEventSelected={setEventSelected}
                                  className={"planning"} logo={logoMerlin}
                                  showSideBar={false} events={eventsFiltered || events}
                                  viewType={"weekBis"}
                                  day={day ? day : moment()}
                                   setDay={setDay}
                                  plageDebut={8} plageFin={17}  date={date} setDate={setDate}
                                  localDayJS={"fr"}
                />
            )}
            <Suspense fallback={<Loading />}>
                <ModalActivite fetchPlanningActivite={fetchPlanningActivite} isOpen={isOpen} setIsOpen={setIsOpen}
                               clients={optionsClient} prestataires={optionsPrestataire}
                               groupeActivites={optionsGroupeActivite} updateActivite={fetchActiviteWithoutParent}
                               activites={optionsActivitesWithoutParent} updateClient={setOptionsSelectClient} />
                <ModalActiviteEdit isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} clients={optionsClient}
                                   groupeActivites={optionsGroupeActivite} prestataires={optionsPrestataire}
                                   activites={optionsActivitesWithoutParent}
                                   activiteC={eventSelected.activite?.value || {}} setEventSelected={setEventSelected}
                                   fetchPlanningActivite={fetchPlanningActivite} updateClient={setOptionsSelectClient}/>
            </Suspense>
        </>
    );
};

export default Planning;